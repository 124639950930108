import React, { useState, useContext } from 'react';
import { TextField, Button, Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { login } from '../../services/users';
import { UserContext } from '../../context/UserProvider.jsx';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.spacing(1),
    boxShadow: theme.shadows[3],
    backgroundColor: 'white',
  },
  textField: {
    marginBottom: theme.spacing(2),
    backgroundColor: 'white',
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

const LoginPage = () => {
  const { setToken, setUserType } = useContext(UserContext);
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = () => {
    if (email === '' || password === '') {
      return alert('Please enter email and password');
    }

    login({
      'email': email,
      'password': password,
    }).then(res => {
      if (res['status'] === 'success') {
        setToken(res['data']['ResetCode']);
        setUserType(res['data']['Type']);
      } else {
        alert('Invalid email address or password');
      }
    });
  };

  return (
    <Container maxWidth="xs" className={classes.container}>
      <Typography variant="h4" align="center" gutterBottom>
        Login
      </Typography>
      <form>
        <TextField
          className={classes.textField}
          label="Email Address"
          variant="outlined"
          fullWidth
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          className={classes.textField}
          label="Password"
          variant="outlined"
          type="password"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={{ marginTop: '1rem' }}
        />

        <Button
          className={classes.button}
          variant="contained"
          fullWidth
          onClick={handleLogin}
          style={{ marginTop: '1rem' }}
          color="success"
        >
          Login
        </Button>
      </form>
    </Container>
  );
};

export default LoginPage;
