import React, { useState, useContext, useEffect, useRef } from 'react'
import ResponsiveAppBar from '../../components/Navbar'
import { getList } from '../../services/manifest'
import { UserContext } from '../../context/UserProvider.jsx'
import { DataGrid } from '@mui/x-data-grid'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import { Button, FormGroup, TextField, withStyles } from '@material-ui/core'
import { useNavigate } from 'react-router-dom';
import Select from 'react-select'
import LoadinScreen from '../../components/LoadingScreen'
import LockIcon from '@mui/icons-material/Lock';
import {BrowserView, MobileView} from 'react-device-detect';

const App = () => {
  const navigate = useNavigate();
  const { token } = useContext(UserContext)
  const [list, setList] = useState(null)

  useEffect(() => {
    loadList();
  }, [])

  const loadList = () => {
    getList(token, {}).then((res) => {
      setList(res)
    })
  }

  const handleClick = (key, lock) => {
    if (lock) {
      return alert('This manifest is locked. Please complete first the active manifest.')
    }

    navigate('/manifest/' + key);
  }

  const RenderMobileList = () => {
    if (Object.keys(list).length == 0) {
      return <div style={{textAlign: 'center', paddingTop: '50px'}}>
        <h3>No Assigned Manifest</h3>
      </div>
    }

    const listDom = [];
    for (const [key, value] of Object.entries(list)) {
      const date = new Date(key);
      const dateStr = date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });

      listDom.push(<div 
        style={{
          borderBottom: 'solid 1px #cacaca',
          padding: '15px 10px 0px 10px',
          float: 'left',
          width: '100%'
        }}
        onClick={() => handleClick(key, value['lock'])}
        key={'manifest-' + key}
      >
        <div style={{
          width: '50%',
          float: 'left'
        }}>
          {value['lock'] && <LockIcon />} <h3>{dateStr}</h3>
        </div>
        <div style={{
          width: '50%',
          float: 'left',
          marginTop: '10px',
          color: '#747474'
        }}>
          <p style={{margin:'0px'}}><strong>For Box Delivery:</strong> {value['total-box-delivery']}</p>
          <p style={{margin:'0px'}}><strong>For Pickup:</strong> {value['total-pickup']}</p>
          <p style={{margin:'0px'}}><strong>For Delivery:</strong> {value['total-delivery']}</p>
          <p style={{margin:'0px', marginBottom: '20px'}}><strong>Total:</strong> {value['total']}</p>
        </div>
      </div>)

    }
  
    return <>{listDom}</>;
  }

  const RenderDesktopList = () => {
    const columns = [
      { field: 'date', headerName: 'Date', width: 200 },
      { field: 'total-pickup', headerName: 'For Pickup', width: 200 },
      { field: 'total-box-delivery', headerName: 'For Box Delivery', width: 200 },
      { field: 'total-delivery', headerName: 'For Box Delivery', width: 200 },
      { field: 'total', headerName: 'Total', width: 200 },
      {
        field: 'action',
        headerName: 'Action',
        width: 200,
        renderCell: (params) => (
          <>
          {params.row.lock 
          ? <div style={{marginLeft: '35px'}}><LockIcon /></div>
          : <strong>
              <Button

                variant="contained"
                color="primary"
                size="small"
                style={{ marginLeft: 16 }}
                onClick={() => handleClick(params.row.date, params.row.lock)}
              >
                View
              </Button>
          </strong>
          }
          </>
        ),
      },
    ]

    const rows = []
    for (const [key, value] of Object.entries(list)) {
      rows.push({
        id: key,
        date: key,
        'total-pickup': value['total-pickup'],
        'total-box-delivery': value['total-box-delivery'],
        total: value['total'],
        lock: value['lock'],
      })
    }

    return (
      <div style={{ height: 400, width: '100%' }}>
        <DataGrid rows={rows} columns={columns} pageSize={5} hideFooterPagination={true}  />
      </div>
    )
  }
    
  if (!list) {
    return <LoadinScreen />
  }

  return (
    <>
      <ResponsiveAppBar />
      <Container maxWidth={'xl'}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <h1>Manifest</h1>
          </Grid>
        </Grid>
        <div
          style={{
            minHeight: '80vh',
            width: '100%',
            backgroundColor: '#ffff',
            marginTop: '20px',
          }}
        >
          <BrowserView>
            <RenderDesktopList />
          </BrowserView>

          <MobileView>
            <RenderMobileList />
          </MobileView>
        </div>
      </Container>
    </>
  )
}

export default App
