import React, { useState, useContext, useEffect, useRef } from 'react'
import ResponsiveAppBar from '../../components/Navbar'
import { getInformation, close } from '../../services/joborder'
import { UserContext } from '../../context/UserProvider.jsx'
import { DataGrid } from '@mui/x-data-grid'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import { Button, FormGroup, TextField, withStyles } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import LoadinScreen from '../../components/LoadingScreen'
import ImageUploader from '../../components/ImageUploader'
import LockIcon from '@mui/icons-material/Lock'
import { isMobile } from 'react-device-detect'
import { useParams } from 'react-router-dom'
import Chip from '@mui/material/Chip'

const App = () => {
  const navigate = useNavigate()
  const { token } = useContext(UserContext)
  const { id, date } = useParams()
  const [jobOrder, setJobOrder] = useState(null)
  const photoList = useRef([])
  const imageNeeded = useRef(2)

  useEffect(() => {
    console.log('photoList', photoList)
  }, [photoList])

  useEffect(() => {
    loadJobOrder()
  }, [])

  const loadJobOrder = () => {
    getInformation(token, id).then((res) => {
      setJobOrder(res)
    })
  }

  const submitHandler = () => {
    const confirmation = window.confirm('Are you sure you want to submit?')

    if (!confirmation) {
      return
    }

    if (2 !== photoList.current.length) {
      return alert('Please upload all the images needed.')
    }

    const payload = {
      id: id,
      images: photoList.current,
      status: 'Driver Upload Proof of Box Delivery',
      type: 'For Box Delivery',
    }

    close(token, payload).then((res) => {
      navigate('/manifest/' + date)
    })
  }

  const RenderPackageList = () => {
    // REMOVE ONLY RECIV
    // const itemList = jobOrder?.services?.raw

    // if (!itemList) {
    //   return null
    // }

    // const domSummary = []
    // imageNeeded.current = 1
    // const typeNotAllow = [
    //   'AC',
		// 	'PU',
		// 	'RA',
		// 	'PF',
		// 	'EXT',
		// 	'HNDL'
    // ];

    // for (const [key, value] of Object.entries(itemList)) {
    //   for (let i = 0; i < value.Qty; i++) {
    //     let keyId = key.split('-')[0];
    //     if (typeNotAllow.includes(keyId)) {
    //       continue;
    //     }

    //     imageNeeded.current = imageNeeded.current + 1
    //     domSummary.push(
    //       <div
    //         key={'item-' + key}
    //         style={{
    //           paddingBottom: '10px',
    //           borderBottom: 'solid 1px #74b6f6',
    //         }}
    //       >
    //         <ImageUploader
    //           name={`photo-1-box-${key}-no-${i}`}
    //           label={`Box Photo`}
    //           title={value.Label}
    //           ref={photoList}
    //         />

    //         <span>
    //           <strong>{value.Label}</strong> - x{value.Qty}
    //         </span>
    //         <br />
    //       </div>,
    //     )
    //   }
    // }

    return (
      <>
        <h2>Package List</h2>
        <ImageUploader
          name={`drop-off-location`}
          title={`Receiver Photo / Location / Address Plate`}
          ref={photoList}
        />
        <ImageUploader
          name={`proof-of-payment`}
          title={`Proof of Payment / Official Receipt Photo`}
          ref={photoList}
        />
        <Button
          variant="contained"
          color="primary"
          size="small"
          style={{ marginTop: '10px', marginBottom: '10px' }}
          onClick={() => submitHandler()}
          fullWidth={isMobile}
        >
          Submit
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          style={{ marginTop: '10px', marginBottom: '10px' }}
          onClick={() => {
            const confirmation = window.confirm(
              'Are you sure you want to cancel?',
            )

            if (!confirmation) {
              return
            }

            navigate('/manifest/' + date)
          }}
          fullWidth={isMobile}
        >
          Cancel
        </Button>
      </>
    )
  }

  return (
    <>
      <ResponsiveAppBar />
      <Container maxWidth={'xl'}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <h2>Delivery Box Form</h2>
          </Grid>
          <Grid item xs={6}>
            <h2></h2>
          </Grid>
        </Grid>
        <div
          style={{
            minHeight: '80vh',
            backgroundColor: '#ffff',
            marginTop: '20px',
            padding: '10px',
          }}
        >
          <RenderPackageList />
        </div>
      </Container>
    </>
  )
}

export default App
