import axios from 'axios';

export const getList = (token, payload) => {
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
    };

    let params = '';
    for (const [key, value] of Object.entries(payload)) {
        params += `${key}=${value}&`;
    }

    return axios.get(`${process.env.REACT_APP_API}/manifest/?${params}`, { headers })
    .then(function(response) {
        return response.data;
    })
    .catch(error => {
        if (error.response.status === 401)  {
            localStorage.removeItem('token');
            return window.location.href = '/';
        } else if (error.response.status === 404) {
            // return window.location.href = '/404';
        }

        return error.response;
    });
}