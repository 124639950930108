import './App.css'
import { createTheme, ThemeProvider } from '@mui/material'
import Controller from './pages/Controller'
import UserProvider from './context/UserProvider';

const theme = createTheme({})

function App() {
  return (
    <div style={{ backgroundColor: '#e5e5e5', minHeight: '100vh' }}>
      <UserProvider>
        <ThemeProvider theme={theme}>
          <Controller />
        </ThemeProvider>
      </UserProvider>
      {process.env.NODE_ENV !== 'production' && <small>You are running this application in <b>{process.env.NODE_ENV}</b> mode.</small>}
    </div>
  )
}

export default App
