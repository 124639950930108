import React, { createContext, useEffect, useState, useRef } from 'react'

const UserContext = createContext()

const UserProvider = ({ children }) => {
  const [token, setToken] = useState(null)
  const [userType, setUserType] = useState(null)
  const isMountedRef = useRef(false)

  useEffect(() => {
    const tokenCache = localStorage.getItem('token')
    const userTypeCache = localStorage.getItem('userType')

    if (tokenCache) {
      setToken(tokenCache)
    }

    if (userTypeCache) {
      setUserType(userTypeCache)
    }

    isMountedRef.current = true
  }, [])

  useEffect(() => {
    if (isMountedRef.current) {
      if (token) {
        localStorage.setItem('token', token)
      } else {
        localStorage.removeItem('token')
      }
    }
  }, [token])

  useEffect(() => {
    if (isMountedRef.current) {
      if (userType) {
        localStorage.setItem('userType', userType)
      } else {
        localStorage.removeItem('userType')
      }
    }
  }, [userType])


  const logout = () => {
    setToken(null)
  }

  return (
    <UserContext.Provider
      value={{
        token,
        setToken,
        logout,
        userType,
        setUserType
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider
export { UserContext }
