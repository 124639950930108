import React, { useContext } from 'react'
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom'
import { UserContext } from '../context/UserProvider.jsx'
import LoginPage from './LoginPage'
import NotFound from './NotFound'
import Dashboard from './Dashboard'
import Information from './Dashboard/information'
import PickupForm from './Dashboard/pickupForm'
import DeliveryBoxForm from './Dashboard/deliveryBoxForm'
import DeliveryForm from './Dashboard/deliveryForm'

function Controller() {
  const { token, userType, access } = useContext(UserContext)

  if (!token) {
    return <LoginPage />
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/manifest" element={<Dashboard />} />
        <Route path="/manifest/:id" element={<Information />} />
        <Route path="/manifest/:date/pickup/:id" element={<PickupForm />} />
        <Route path="/manifest/:date/delivery-box/:id" element={<DeliveryBoxForm />} />
        <Route path="/manifest/:date/delivery/:id" element={<DeliveryForm />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  )
}

export default Controller
