import axios from 'axios';
import md5 from 'md5';

export const login = (payload) => {
    return axios.post(`${process.env.REACT_APP_API}/authenticate`, payload)
    .then(function(response) {
        return response.data;
    })
    .catch(error => {
        return error.response.status;
    });
}