import React, { useEffect, useState, forwardRef } from 'react'
import { create } from '../../services/file'

const ImageUpload = forwardRef(
  ({ label = 'Upload Image', name, title }, ref) => {
    const [selectedImage, setSelectedImage] = useState(null)
    const [url, setUrl] = useState(null)

    const handleImageChange = (event) => {
      if (event.target.files[0].size > 5242880) {
        return alert('File size cannot exceed more than 5MB');
      }

      const imageFile = event.target.files[0]
      create({
        file: imageFile,
      }).then((res) => {
        if (res.status) {
          setSelectedImage(null);
          setUrl(null);
          return alert('Image too large or invalid format. Please upload new image.');
        }

        setUrl(res.url)
        if (res.url) {
          let nameKey = title + ': ' + label
          ref.current.push({
            name: nameKey,
            url: res.url,
          })
        }
      })
      setSelectedImage(URL.createObjectURL(imageFile))
    }

    const handleRemove = () => {
      const temp = ref.current.filter((item) => {
        return item.url !== url
      })
      ref.current = temp;
      setUrl(null)
      setSelectedImage(null)
    }

    return (
      <div>
        <input
          type="file"
          accept={'image/jpeg, image/jpg'}
          onChange={handleImageChange}
          style={{ display: 'none' }}
          id={'file-input-' + name}
        />
        {!selectedImage && (
          <label htmlFor={'file-input-' + name}>
            <div
              style={{
                width: '100%',
                textAlign: 'center',
                height: '100px',
                background: '#e3e3e3',
                paddingTop: '70px',
                marginTop: '20px',
                marginBottom: '20px',
              }}
            >
              {title}
              <br />
              {label}
            </div>
          </label>
        )}
        {selectedImage && (
          <div>
            <img src={selectedImage} alt="Selected" style={{ width: '100%' }} />
            <div
              style={{
                width: '100%',
                background: 'red',
                padding: '10px 0px 10px 0px',
                textAlign: 'center',
                color: 'white',
                marginBottom: '15px',
              }}
              onClick={() => handleRemove()}
            >
              Remove
            </div>
          </div>
        )}
      </div>
    )
  },
)

export default ImageUpload
