import axios from 'axios'

export const create = (payload) => {
  const headers = {
    'Content-Type': 'multipart/form-data',
  }

  const formData = new FormData()

  for (const key in payload) {
    formData.append(key, payload[key])
  }

  return axios
    .post(`${process.env.REACT_APP_API}/file`, formData, {
      headers,
    })
    .then(function (response) {
      return response.data
    })
    .catch((error) => {
      if (error.response.status === 401) {
        localStorage.removeItem('token')
        return (window.location.href = '/')
      } else if (error.response.status === 404) {
        alert('Customer ID not found')
      }

      return error.response
    })
}
