import axios from 'axios';

export const getInformation = (token, id) => {
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
    };

    return axios.get(`${process.env.REACT_APP_API}/job-order/${id}`, { headers })
    .then(function(response) {
        return response.data;
    })
    .catch(error => {
        if (error.response.status === 401)  {
            localStorage.removeItem('token');
            return window.location.href = '/';
        } else if (error.response.status === 404) {
            // return window.location.href = '/404';
        }

        return error.response;
    });
}

export const close = (token, payload) => {
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
    };

    return axios.post(`${process.env.REACT_APP_API}/job-order/driver-close`,payload, { headers })
    .then(function(response) {
        return response.data;
    })
    .catch(error => {
        if (error.response.status === 401)  {
            localStorage.removeItem('token');
            return window.location.href = '/';
        } else if (error.response.status === 404) {
            // return window.location.href = '/404';
        }

        return error.response;
    });
}

export const reschedule = (token, payload) => {
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
    };

    return axios.post(`${process.env.REACT_APP_API}/job-order/driver-reschedule`,payload, { headers })
    .then(function(response) {
        return response.data;
    })
    .catch(error => {
        if (error.response.status === 401)  {
            localStorage.removeItem('token');
            return window.location.href = '/';
        } else if (error.response.status === 404) {
            // return window.location.href = '/404';
        }

        return error.response;
    });
}