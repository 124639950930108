import React, { useState, useContext, useEffect, useRef } from 'react'
import ResponsiveAppBar from '../../components/Navbar'
import { getList } from '../../services/manifest'
import { reschedule as reschedJobOrder } from '../../services/joborder'
import { UserContext } from '../../context/UserProvider.jsx'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import { Button, FormGroup, TextField, withStyles } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import LoadinScreen from '../../components/LoadingScreen'
import { isMobile } from 'react-device-detect'
import { useParams } from 'react-router-dom'
import Chip from '@mui/material/Chip'

const App = () => {
  const navigate = useNavigate()
  const { token } = useContext(UserContext)
  const [list, setList] = useState(null)
  const { id } = useParams()
  const [selected, setSelected] = useState(null)
  const [reschedule, setReschedule] = useState(false)
  const date = new Date(id)
  const dateStr = date.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  })

  useEffect(() => {
    loadList()
  }, [])

  const loadList = () => {
    getList(token, { date: id }).then((res) => {
      setList(res)
    })
  }

  const handleClick = (value) => {
    setSelected(value)
  }

  const RenderList = () => {
    if (Object.keys(list).length == 0) {
      return (
        <div style={{ textAlign: 'center', paddingTop: '50px' }}>
          <h3>No Active Job Order for this date.</h3>
          <a href="/manifest">Back to Manifest List</a>
        </div>
      )
    }

    const listDom = []
    const joborderList = list[id]['list']
    for (const [key, value] of Object.entries(joborderList)) {
      const services = JSON.parse(value['jo-information']['ServicesLog'] ?? '{}')
      console.log('xxxxxx', services);
      listDom.push(
        <div
          key={'manifest-' + key}
          onClick={() => {
            if (value['jo-status'] == 'active') {
              handleClick(value)
            } else {
              alert('Job Order is already completed.')
            }
          }}
          style={{
            borderBottom: 'solid 1px #cacaca',
            width: '100%',
          }}
        >
          <Grid container spacing={2}>
            <Grid item md={6}>
              <p>
                <strong>{value['jo-information']['CurrentStatus']}</strong> -{' '}
                {dateStr} @ {value['jo-information']['PickupTime']}
              </p>
              <p>
                <strong>Ref #:</strong> {value['jo-information']['RefID']}
              </p>
              <p>
                <strong>Status:</strong>{' '}
              </p>
              <Chip
                label={value['jo-status']}
                color={value['jo-status'] == 'active' ? 'error' : 'success'}
              />

              {value['jo-information']['CurrentStatus'] == 'For Pickup' && (
                <>
                  {value['customer-information']['Company'] != '' && (
                    <p>
                      <strong>Company:</strong>{' '}
                      {value['customer-information']['Company']}
                    </p>
                  )}
                  <p>
                    <strong>Customer:</strong>{' '}
                    {value['customer-information']['FirstName']}{' '}
                    {value['customer-information']['LastName']}
                  </p>
                  <p>
                    <strong>Contact #:</strong>{' '}
                    {value['customer-information']['Number']}
                  </p>
                  <p>
                    <strong>Pickup Address:</strong>{' '}
                    {value['jo-information']['PickupAddress1']}{' '}
                    {value['jo-information']['PickupAddress2']}{' '}
                    {value['jo-information']['PickupCity']}{' '}
                    {value['jo-information']['PickupState']}{' '}
                    {value['jo-information']['PickupCountry']}{' '}
                    {value['jo-information']['PickupZip']}{' '}
                  </p>
                </>
              )}

              {value['jo-information']['CurrentStatus'] ==
                'For Box Delivery' && (
                <>
                  {value['customer-information']['Company'] != '' && (
                    <p>
                      <strong>Company:</strong>{' '}
                      {value['customer-information']['Company']}
                    </p>
                  )}
                  <p>
                    <strong>Customer:</strong>{' '}
                    {value['customer-information']['FirstName']}{' '}
                    {value['customer-information']['LastName']}
                  </p>
                  <p>
                    <strong>Contact #:</strong>{' '}
                    {value['customer-information']['Number']}
                  </p>
                  <p>
                    <strong>Delivery Address:</strong>{' '}
                    {value['jo-information']['PickupAddress1']}{' '}
                    {value['jo-information']['PickupAddress2']}{' '}
                    {value['jo-information']['PickupCity']}{' '}
                    {value['jo-information']['PickupState']}{' '}
                    {value['jo-information']['PickupCountry']}{' '}
                    {value['jo-information']['PickupZip']}{' '}
                  </p>
                </>
              )}

              {(value['jo-information']['CurrentStatus'] ==
                'Schedule for Delivery' ||
                value['jo-information']['CurrentStatus'] ==
                  'Ongoing Delivery') && (
                <>
                  {value['jo-information']['ConsigneeCompany'] != '' && (
                    <p>
                      <strong>Consignee Company:</strong>{' '}
                      {value['jo-information']['ConsigneeCompany']}
                    </p>
                  )}
                  <p>
                    <strong>Consignee Name:</strong>{' '}
                    {value['jo-information']['ConsigneeName']}
                  </p>
                  <p>
                    <strong>Consignee Contact #:</strong>{' '}
                    {value['jo-information']['ConsigneeContactNumber']}
                  </p>
                  <p>
                    <strong>Delivery Address:</strong>{' '}
                    {value['jo-information']['DestAddress1']}{' '}
                    {value['jo-information']['DestAddress2']}{' '}
                    {value['jo-information']['DestCity']}{' '}
                    {value['jo-information']['DestState']}{' '}
                    {value['jo-information']['DestCountry']}{' '}
                    {value['jo-information']['DestZip']}{' '}
                  </p>
                </>
              )}
            </Grid>
            <Grid item md={6}>
              <strong>Package Size</strong>
              {services?.raw && Object.keys(services?.raw).map((key) => {
                return (
                  <p key={'services' + key}>
                    {services.raw[key].Label} - {services.raw[key].Qty}
                  </p>
                )
              })}
            </Grid>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginTop: '10px', marginBottom: '10px' }}
            onClick={() => {
              if (value['jo-status'] == 'active') {
                handleClick(value)
              } else {
                alert('Job Order is already completed.')
              }
            }}
            fullWidth={isMobile}
          >
            View
          </Button>
        </div>,
      )
    }

    return (
      <div
        style={{
          padding: '15px 10px 0px 10px',
        }}
      >
        {listDom}
        <Button
          variant="contained"
          color="primary"
          size="small"
          style={{ marginTop: '10px', marginBottom: '10px' }}
          onClick={() => {
            setSelected(null)
            navigate('/manifest')
          }}
          fullWidth={isMobile}
        >
          Back
        </Button>
      </div>
    )
  }

  const RenderSelected = () => {
    const services = JSON.parse(selected['jo-information']['ServicesLog'])
    const serviceDom = []
    if (services?.raw) {
      for (const [key, value] of Object.entries(services.raw)) {
        serviceDom.push(
          <p key={'services' + key}>
            {value.Label} - {value.Qty}
          </p>,
        )
      }
    }

    return (
      <div
        style={{
          borderBottom: 'solid 1px #cacaca',
        }}
      >
        <Grid container spacing={2} p={2}>
          <Grid item xs={12} md={6}>
            <p>
              <strong>{selected['jo-information']['CurrentStatus']}</strong> -{' '}
              {dateStr} @ {selected['jo-information']['PickupTime']}
            </p>
            <p>
              <strong>Ref #:</strong> {selected['jo-information']['RefID']}
            </p>
            <p>
              <strong>Status:</strong>{' '}
            </p>
            <Chip
              label={selected['jo-status']}
              color={selected['jo-status'] == 'active' ? 'error' : 'success'}
            />

            {selected['jo-information']['CurrentStatus'] == 'For Pickup' && (
              <>
                {selected['customer-information']['Company'] != '' && (
                  <p>
                    <strong>Company:</strong>{' '}
                    {selected['customer-information']['Company']}
                  </p>
                )}
                <p>
                  <strong>Customer:</strong>{' '}
                  {selected['customer-information']['FirstName']}{' '}
                  {selected['customer-information']['LastName']}
                </p>
                <p>
                  <strong>Contact #:</strong>{' '}
                  {selected['customer-information']['Number']}
                </p>
                <p>
                  <strong>Pickup Address:</strong>{' '}
                  {selected['jo-information']['PickupAddress1']}{' '}
                  {selected['jo-information']['PickupAddress2']}{' '}
                  {selected['jo-information']['PickupCity']}{' '}
                  {selected['jo-information']['PickupState']}{' '}
                  {selected['jo-information']['PickupCountry']}{' '}
                  {selected['jo-information']['PickupZip']}{' '}
                </p>
              </>
            )}

            {selected['jo-information']['CurrentStatus'] ==
              'For Box Delivery' && (
              <>
                {selected['customer-information']['Company'] != '' && (
                  <p>
                    <strong>Company:</strong>{' '}
                    {selected['customer-information']['Company']}
                  </p>
                )}
                <p>
                  <strong>Customer:</strong>{' '}
                  {selected['customer-information']['FirstName']}{' '}
                  {selected['customer-information']['LastName']}
                </p>
                <p>
                  <strong>Contact #:</strong>{' '}
                  {selected['customer-information']['Number']}
                </p>
                <p>
                  <strong>Box Delivery Address:</strong>{' '}
                  {selected['jo-information']['PickupAddress1']}{' '}
                  {selected['jo-information']['PickupAddress2']}{' '}
                  {selected['jo-information']['PickupCity']}{' '}
                  {selected['jo-information']['PickupState']}{' '}
                  {selected['jo-information']['PickupCountry']}{' '}
                  {selected['jo-information']['PickupZip']}{' '}
                </p>
              </>
            )}

            {(selected['jo-information']['CurrentStatus'] ==
              'Schedule for Delivery' ||
              selected['jo-information']['CurrentStatus'] ==
                'Ongoing Delivery') && (
              <>
                {selected['jo-information']['ConsigneeCompany'] != '' && (
                  <p>
                    <strong>Consignee Company:</strong>{' '}
                    {selected['jo-information']['ConsigneeCompany']}
                  </p>
                )}
                <p>
                  <strong>Consignee Name:</strong>{' '}
                  {selected['jo-information']['ConsigneeName']}
                </p>
                <p>
                  <strong>Consignee Contact #:</strong>{' '}
                  {selected['jo-information']['ConsigneeContactNumber']}
                </p>
                <p>
                  <strong>Delivery Address:</strong>{' '}
                  {selected['jo-information']['DestAddress1']}{' '}
                  {selected['jo-information']['DestAddress2']}{' '}
                  {selected['jo-information']['DestCity']}{' '}
                  {selected['jo-information']['DestState']}{' '}
                  {selected['jo-information']['DestCountry']}{' '}
                  {selected['jo-information']['DestZip']}{' '}
                </p>
              </>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <strong>Package Size</strong>
            {serviceDom}
            
            {!reschedule && <>
              {selected['jo-information']['CurrentStatus'] === 'For Pickup' && (
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() =>
                    navigate(
                      '/manifest/' +
                        id +
                        '/pickup/' +
                        selected['jo-information']['ID'],
                    )
                  }
                  fullWidth={isMobile}
                  style={
                    isMobile ? { marginBottom: '10px' } : { marginLeft: '10px' }
                  }
                >
                  Submit Proof of Pickup
                </Button>
              )}

              {selected['jo-information']['CurrentStatus'] ===
                'For Box Delivery' && (
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() =>
                    navigate(
                      '/manifest/' +
                        id +
                        '/delivery-box/' +
                        selected['jo-information']['ID'],
                    )
                  }
                  fullWidth={isMobile}
                  style={
                    isMobile ? { marginBottom: '10px' } : { marginLeft: '10px' }
                  }
                >
                  Submit Proof of Box Delivery
                </Button>
              )}

              {(selected['jo-information']['CurrentStatus'] ==
                'Schedule for Delivery' ||
                selected['jo-information']['CurrentStatus'] ==
                  'Ongoing Delivery') && (
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() =>
                    navigate(
                      '/manifest/' +
                        id +
                        '/delivery/' +
                        selected['jo-information']['ID'],
                    )
                  }
                  fullWidth={isMobile}
                  style={
                    isMobile ? { marginBottom: '10px' } : { marginLeft: '10px' }
                  }
                >
                  Submit Proof of Delivery
                </Button>
              )}
            </>}


            {reschedule && <RescheduleSection />}
            <Button
              variant="contained"
              color="primary"
              size="small"
              fullWidth={isMobile}
              style={
                isMobile ? { marginBottom: '10px' } : { marginLeft: '10px' }
              }
              onClick={() => setReschedule(!reschedule)}
            >
              {reschedule ? <>Cancel Reschedule</> : <>Reschedule</>}
            </Button>
            {!reschedule &&
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                  setSelected(null)
                  setReschedule(false)
                }}
                fullWidth={isMobile}
                style={
                  isMobile ? { marginBottom: '10px' } : { marginLeft: '10px' }
                }
              >
                Back
              </Button>
            }
          </Grid>
        </Grid>
      </div>
    )
  }

  const RescheduleSection = () => {
    const currentStatus = selected['jo-information']['CurrentStatus'];
    const statusPackageDelivery = ['Schedule for Delivery', 'Ongoing Delivery'];
    const allowedToReschedule = statusPackageDelivery.includes(currentStatus);
    let dateTomorrow = new Date();
    dateTomorrow.setDate(dateTomorrow.getDate() + 1);
    // format 2020-08-10
    dateTomorrow = dateTomorrow.toISOString().split('T')[0];

    return <>
      <Grid container spacing={2}>
        {allowedToReschedule && 
        <Grid item xs={12} md={6}>
          <FormGroup>
            <TextField

              id="date"
              label="Date"
              type="date"
              defaultValue={id}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: dateTomorrow
              }}
            />
          </FormGroup>
        </Grid>
        }
        <Grid item xs={12} md={6}>
          <FormGroup>
            <TextField
              id="reason"
              label="Reason for Reschedule"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Button
        variant="contained"
        color="primary"
        size="small"
        style={{ marginTop: '10px', marginBottom: '10px' }}
        onClick={() => {
          const confirmation = window.confirm(
            'Are you sure you want to reschedule?',
          )
            
          if (!confirmation) {
            return
          }

          const date = allowedToReschedule ? document.getElementById('date').value : null;
          const reason = document.getElementById('reason').value

          if (reason == '') {
            alert('Please enter a reason.')
            return
          } else if (allowedToReschedule && date == '') {
            alert('Please enter a date.')
            return
          }

          const data = {
            id: selected['jo-information']['ID'],
            type: currentStatus,
            date: date,
            reason: reason,
          }

          reschedJobOrder(token, data).then((res) => {
            alert('Job Order has been rescheduled.')
            window.location.reload()
          });
        }}
        fullWidth={isMobile}
      >
        Submit
      </Button>
    </>
  }

  if (!list) {
    return <LoadinScreen />
  }

  return (
    <>
      <ResponsiveAppBar />
      <Container maxWidth={'xl'}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <h2>Manifest Information</h2>
          </Grid>
          <Grid item xs={6}>
            <h2>{dateStr}</h2>
          </Grid>
        </Grid>
        <div
          style={{
            minHeight: '80vh',
            backgroundColor: '#ffff',
            marginTop: '20px',
          }}
        >
          {selected ? <RenderSelected /> : <RenderList />}
        </div>
      </Container>
    </>
  )
}

export default App
